.becomeASpon_com {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
}
.spon_img {
    height: 120px;
    width: 120px;
    border-radius: 50%;
    border: 1px solid #818181;
    object-fit: cover;
}