.donation_inc_Dec {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 18px;
}
.single_donate_btn {
    height: 100%;
    border: none;
    border-bottom-left-radius: 18px;
    background-color: #ee4236;
    color: white;
    font-weight: bold;
    padding: 10px;
}
.donation_details {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    border-bottom-left-radius: 35px;
    border-bottom-right-radius: 35px;
    background-color: white;
    box-shadow: 1px 1px 8px #88888854;

}
.donation_img {
    border-top-left-radius: 35px;
    border-top-right-radius: 35px;
    width: 190px;
    height: 140px;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    padding: 7px 7px 0px 0px;
}
.like_btn {
    background-color: transparent;
    border: none;
    font-size: 25px;
}
.donation_item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0px;
}
.icon_for_donation {
    height: 48px;
    width: 48px;
    border-radius: 50%;
    background-color: transparent;
}
.donation_com {
    display: flex;
    align-items: flex-start;
    justify-content: center;
}
.donate_btn_wrap {
    border: none;
    z-index: 1000;
    background-color: transparent;
    height: 48px;
    width: 48px;
    border-radius: 50%;
    margin-right: -30px;
    margin-top: -5px;
    align-items: center;
    padding: 0px;
}