.switch_btn {
    padding: 20px;
    background-color: white;
    font-size: 25px;
    width: 100%;
    border: 0px;
    border-radius: 0px;
}
#switch_project_page {
    display: flex;
    align-items: center;
    justify-content: center;
}
.btn-first {
    border-bottom: 2px solid #cc0000;
}