.sukukCom {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;
}
.sukuk_item {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    background-color: white;
    border-radius: 30px;
    box-shadow: 1px -1px 5px #88888844

}
.sukuk_item_img {
    background-size: cover;
    background-position: center;
    height: 250px;
    width: 100%;
    border-top-right-radius: 100px 100px;
    border-bottom-right-radius: 100px 100px;
     border-top-left-radius: 30px;
    border-bottom-left-radius: 30px; 
    opacity: 0.85;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 10px;
}
.sukuk_item_board {
    width: 100%;
    height: 100%;
    padding: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
}
.sukuk_item_board_time, .sukuk_item_board_payment {
    width: 100%;
    gap: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.sukuk_inc_Dec {
    width: 100%;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}
.px30_btn {
    border-radius: 30px;
}
.px50_btn {
    border-radius: 50%;
    font-size: 30px;
}
.sukukinc, .sukukdec {
    border-radius: 50%;
}
.px30_btn:hover {
 background-color: #ee4236;
 color: white;
} 
.px50_btn:hover {
 background-color: #ee4236;
 color: white;
} 
.px30_btn:focus {
    background-color: #ee4236;
    color: white;
   } 
   .px50_btn:focus {
    background-color: #ee4236;
    color: white;
   } 
   @media (max-width: 721px) {
    .sukuk_item_img {
        background-position: center center;
        width: 100%;
        border-top-right-radius: 30px;
        border-bottom-right-radius: 0px;
        border-bottom-left-radius: 0px;
    }
    .px50_btn {
        font-size: 15px;
    }
    .px30_btn {
        font-size: 15px;
    }
    .sukukinc, .sukukdec {
        font-size: 15px;
    }
    .sukuk_item {
        flex-direction: column;
        justify-content: space-between;
    }
}