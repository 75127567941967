#sponsor_page {
    height: 100vh;
}
.sponsor_banner {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    width: 100%;
    height: 400px;
    background-size: cover;
    background-position: center bottom;
    filter: brightness(90%);
    padding: 20px;
}
.sponsor_display_content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    padding: 40px;
}
.payment_section {
    width: 100%;
    display: grid;
    grid-template-columns: 50% 50%;
}
.payment_datails {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    padding: 20px;
    flex: 1;
}
.choose_amount {
    background-color: rgb(255, 255, 255);
    width: 100%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 20px;
    border: 1px solid #cc0000;
    border-radius: 20px;
}
.spin_salary {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    align-self: center;
}
.spin_salary button {
    width: 100%;
    border: none;
    background-color: white;
    padding: 5px;
}
.display_amount {
    background-color: white;
    padding: 20px;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    opacity: 0.6;
}
.human_donation_btns_spon {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}
.human_donation_btns_above_spon {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}