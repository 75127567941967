#all_sukuk_page {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 40px;
}
#ad_for_sukuk {
    background-color: rgb(226, 226, 202);
    padding: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
}
.sukuk_logo {
    height: 150px;
    width: 200px;
    object-fit: cover;
    flex: 1;
}
.all_sukuk_page_display {
    width: 100%;
    padding: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
}
