.donation_inc_Dec_bag {
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-end;
    justify-content: space-between;
    border-radius: 18px;
    flex: 2;
    gap: 10px;
}
.dec, .inc {
    padding: 0.5px 9px;
    border-radius: 42%;
    background-color: #fa8b806e;
    color: #f31f0c;
    border: none;
    font-size: 20px;
    font-weight: bold;
}
.bag_item_com {
    padding: 30px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    background-color: white;
    border-radius: 30px;
    gap: 30px;
}
.bag_item_img {
    height: 150px;
    width: 200px;
    border-radius: 30px;
    object-fit: fill;
}