#donation_banner {
    width: 100%;
    height: 600px;
    background-size: cover;
    background-position: top;
    display: flex;
    flex-direction: column;
    align-items: center;
    filter: brightness(90%);
    padding: 10px;
}
.search_input {
    flex: 1;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.search_input input {
    width: 35%;
    border: none;
    padding: 10px;
    border-radius: 20px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}
.search_input button {
    padding: 10px;
    border: none;
    border-radius: 20px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    background-color: white;
}
#donation_banner h1 {
    flex: 5;
    color: rgb(255, 255, 255);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}