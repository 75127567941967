#login_sign_img {
    background-size: cover;
    background-position: right;
    height: 500px;
    width: 625px;
    clip-path: polygon( 70% 0, 100% 70%, 70% 100%, 0 70%);
    align-self: center;
}
#login_sign_img2 {
    background-size: cover;
    background-position: center;
    height: 400px;
    width: 500px;
    clip-path: polygon(60% 0, 100% 40%, 50% 100%, 0 50%);
}
#login_sign {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    padding: 20px;
}
.shapes_login {
    opacity: 0.4;
    padding: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.shapes_login:hover  {
    opacity: 1;
}
#login_sign form {
    width: 100%;
    padding: 40px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 30px;
}
#login_sign .input {
    width: 100%;
    background-color: #ececec;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
#login_sign .input input {
    border: 0px;
    background-color: #ececec;
    border-bottom: 1px solid #47464648;

}
#login_sign .input label {
    color: #474646;
    opacity: .55;
}
#login_sign .input a {
    color: #cc0000;
    align-self: flex-end;
    font-weight: bold;
}
.whole_input {
    background-color: #ececec;
    padding: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    gap: 20px;
    border-radius: 5px;
}
.btn_for_form {
    width: 100%;
    border-radius: 30px;
}
.hrs {
    width: 100%;
    display: flex;
    gap: 10px;
}
.hrs hr {
    flex-grow: 1;
    color: #cc0000;
}
.login_sign_social_logos {
    height: 70px;
    width: 70px;
}
.social_media_log {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}
@media (max-width: 1000px) {
    #login_sign {
        flex-direction: column;
        padding: 0px;
    }
    #login_sign_img {
        height: 300px;
        width: 100%;
        clip-path: none;
        display: flex;
        align-items: center;
        justify-content: center;
        background-image: none;
    }
    .shapes_login {
        opacity: 1;
        padding: 0px;
        filter: brightness(80%);
    }
}