#orphan_report {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    min-height: 100vh;
}
#orphan_person_details {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-color: rgb(253, 231, 231);
}
#orphan_person_info {
    padding: 30px;
    width: 100%;
    gap: 40px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}
#orphan_person_info_left_side, #orphan_person_info_right_side {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
}
.orphan_person_img {
    height: 150px;
    width: 150px;
    border-radius: 50%;
    object-fit: cover;
}
.person_img_name {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    background: linear-gradient(to bottom, rgb(253, 231, 231) 0%, rgb(253, 231, 231) 50%, #f5f5f5 50%, #f5f5f5 100%);
}
.data_box {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    background-color: white;
    border-radius: 10px;
}
.title_bar {
    width: 100%;
    padding: 7px;
    background-color: #cccccc;
    color: #4b4b4b;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}
.title_bar button {
    background-color: transparent;
    border: none;
}
.tables_display_section {
    padding: 40px;
    width: 100%;
    display: grid;
    grid-template-columns: 40% 40%;
    justify-content: space-evenly;
    align-items: flex-start;
    gap: 20px;
}
.some_cell_in_table {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 7px;
}
.sample_table_body {
    width: 100%;
    padding: 20px;
    display: grid;
    grid-template-columns: auto auto auto;
    align-items: center;
    gap: 40px;
    justify-content: space-between;
    flex-wrap: wrap;
    background-color: white;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;

}
.column_table_body {
    width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 30px;
}
.table_down_cells {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}