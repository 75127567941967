#projects_sec {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 40px;
    gap: 20px;
}
.project_header {
    width: 100%;
    height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    padding: 20px;
    background-size: cover;
    gap: 10px;
    background-position: center


    ;
    filter: brightness(95%)
}

.project_header_btn {
    background-color: rgba(0, 0, 0, 0.678);
    padding: 2px 60px 2px 60px;
    border: 1px solid black;
    border-radius: 5px;
    color: white;
}
.projects_display {
    padding: 20px;
    width: 100%;
    display: grid;
    grid-template-columns: auto auto auto auto;
    justify-content: space-evenly;
    align-items: center;
    gap: 20px;
}
#projects {
    width: 100%;
    padding: 45px;
}
#projects .carousel-indicators {
    background-color: #fdfdfd;
    margin: 0px;
    align-items: center;
}
#projects .carousel-indicators [data-bs-target] {
    background-color: rgb(99, 99, 99);
    border-radius: 50%;
    width: 10px;
    height: 10px;
    margin: 20px 5px 0px 0px
}
#projects .carousel-caption {
    padding-bottom: 3rem;
}
#projects .carousel-indicators .active {
    background-color: #ee4236;
}
#projects .carousel-control-next, #projects .carousel-control-prev {
    width: 2%;
    opacity: 1;
}
#projects .carousel-control-next-icon  {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ee4236'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e")
}
#projects .carousel-control-prev-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ee4236'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}
@media (max-width: 1600px) {
    .project_header {
        height: 200px;
    }
    #projects {
        padding: 0px;
    }
}
@media (max-width: 922px) {
    .projects_display {
        grid-template-columns: auto auto auto;
    }
}
@media (max-width: 750px) {
    .projects_display {
        grid-template-columns: auto auto;
    }
}
@media (max-width: 370px) {
    .projects_display {
        grid-template-columns: auto;
    }
}