#donation_support_page {
    padding: 40px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 20px;
    height: 100vh;
}
.donation_support_page_display {
    width: 100%;
    display: grid;
    grid-template-columns: 49% 49%;
    align-items: center;
    justify-content: space-between;
}
.donation_support_page_left {
    width: 100%;
}
.donation_support_page_right {
    padding: 30px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}
.support {
    width: 100%;
    height: 800px;
    object-fit: cover;
    object-position: top center;
    border-radius: 30px;
}
.donation_support_page_section {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
}
.donation_frequ_btns {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    gap: 5px;
}
.btn-frequ {
    padding: 10px 40px 10px 40px;
    border-radius: 30px;
    background-color: rgb(255, 255, 255);
}
.donation_amount_btns {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 40px;
}
.slider {
    -webkit-appearance: none;
    width: 80%;
    height: 15px;
    background: #d1d1d1;
    outline: none;
    opacity: 0.7;
    -webkit-transition: .2s;
    transition: opacity .2s;
    border-radius: 15px;
  }
  .slider:hover {
    opacity: 1;
  }
  .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #cc0000;
    cursor: pointer;
  }
  .slider::-moz-range-thumb {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #cc0000;
    cursor: pointer;
  }
  .donation_amount_input {
      width: 70%;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      gap: 20px;
      background-color: white;
      padding: 7px;
  }
  .donation_amount_input input {
      width: 100%;
      background-color: transparent;
      border: none;
      font-weight: bold;
      font-size: 35px;
      padding: 0px;
      line-height: 0px; 
      flex: 1;     
  }
  .donation_amount_input label {
      width: 100%;
      text-align: right;
      font-size: 20px;
      color: rgba(0, 0, 0, 0.384);
      flex: 1;
  }