.becomeASpon {
    width: 100%;
    background-color: #f1f1f1;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 20px;
    flex-wrap: wrap;
}
#becomeASpon_sec {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 40px;
    gap: 20px;
}