#human_case_page {
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 15px;
}
.human_case_display {
    padding: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}
.human_img {
    height: 600px;
    width: 100%;
    border-radius: 30px;
    object-fit: cover;
}
.human_case_img {
    height: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    width: 100%;
    flex: 1;
}

.human_case_details {
    height: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    flex: 1;
}
.human_donation_amount {
    width: 100%;
    gap: 10px;
    background-color: #f1f1f1;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    margin-top: 20px;
}
.human_donation_amount input {
    border: 0px;
    padding: 20px;
    width: 60%;
}
.human_donation_btns {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 50px;
}
.human_donation_btns_above {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}
.apple_human_btn {
    width: 100%;
}
.human_donate_ways {
    border-radius: 30px;
    padding: 20px;
}
.btn1 {
    background-color: #ffe1e1;
    color: #c92a2a;
}
.btn2 {
    background-color: rgb(252, 252, 217);
    color: rgb(116, 116, 5);
}
.btn3 {
    background-color: lightblue;
    color: rgb(30, 183, 235);
}
.text_for_svg {
    font-weight: bold;
    font-size: 25px;
}
.text_for_svg_down {
    font-weight: bold;
    font-size: 15px;
}