.project_img {
    height: 250px;
    width: 250px;
    border-radius: 10px;
    filter: brightness(90%)
}
.projects_com {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
@media (max-width: 1600px) {
    .project_img {
        height: 80px;
        width: 80px;
    }
}