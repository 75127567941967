#favorites_display {
    padding: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;
    overflow-x: auto;
    white-space: nowrap;

}
#favorites_sec {
    padding: 40px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.section_title_div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.section_button {
    border: none;
    background-color: transparent;
    width: 100px;
    color: #cc0000;
    font-weight: bold;
}