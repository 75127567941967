#footer_up {
    width: 100%;
    padding: 40px;
    background-color: #ee4236;
    clip-path: polygon(0% 0%, 0% 100%, 100% 0%);
}
.ERC_logo_footer {
    height: 50px;
    width: 200px;
    object-fit: fill;
}
#footer_down {
    width: 100%;
    padding: 40px 40px 0px 40px;
    background-color: #ee4236;
    clip-path: polygon(0% 100%, 100% 100%, 100% 0%);
}
#real_footer {
    width: 100%;
    padding: 20px;
    min-height: 200px;
    display: flex;
    align-items: flex-start;
    justify-content: space-evenly;
    flex-wrap: wrap;
    background-color: #fdfdfd;
}
#about_US {
    text-decoration: none;
    list-style: none;
}
#about_US li a {
    text-decoration: none;
    color: rgba(0, 0, 0, 0.493);
}
#title_link {
    text-decoration: underline;
}
#name, #email {
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.329);
}
#message {
    border: none;
    border-top: 1px solid rgba(0, 0, 0, 0.329);
    width: 100%;
    height: 150px;
}
.input {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.form_inputs_inline {
    display: flex;
    align-items: center;
    gap: 5px;
    background-color: white;
    padding: 7px;
    border-radius: 5px;
}
#first_inputs {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    box-shadow: 1px 1px 8px #88888825;

}
.messageInput {
    width: 100%;
    background-color: rgb(255, 255, 255);
    padding: 7px;
    border-radius: 5px;
    box-shadow: 1px 1px 8px #88888811;

}
.contactBtn {
    width: 100%;
    border-radius: 30px;
    background-color: #ee4236;
}
footer form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;

}
#contact_us {
    display: flex;
    align-items: flex-start;
    justify-content: space-evenly;
    gap: 10px;
}
#footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
}
.social_media {
    padding: 10px;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    font-size: 40px;
    color: white;
}
#footer_down {
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-end;
    justify-content: flex-end;
}
@media (max-width: 1600px) {
   #real_footer {
       gap: 20px;
   }

   .form_inputs_inline {
       width: 100%;
   }
   #first_inputs input {
       width: 100%;
   }
   .social_media i {
       font-size: 15px;
   }
   #footer_down label {
       font-size: 10px;
   }

   #footer_down {
       padding: 20px 0px 0px 40px;
   }
   #contact_us {
       width: 100%;
       padding: 30px;
       flex-direction: column;
       justify-content: center;
       align-self: flex-end;
   }
   #contact_us button {
       width: 60%;
   }
   #real_footer {
       flex-direction: column;
       gap: 0px;
   }
   .ERC_logo_footer {
       width: 150px;
   }
   #footer_up {
       padding: 10px 0px 40px 20px;
   }
}
@media (max-width: 350px) {
    #first_inputs {
        flex-direction: column;
    }
    #contact_us {
        width: 100%;
    }
    .social_media i {
        font-size: 13px;
    }
    #footer_down label {
        font-size: 9px;
    }
    #footer_down {
        padding: 20px 0px 0px 70px;
    }
}