.donation_page_display {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 10px;
    flex-wrap: wrap;
    gap: 40px;
}
#donation_page_display {
    padding: 40px;
    display: flex;
    flex-direction: column;
    gap: 50px;
}