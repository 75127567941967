.human_desc {
    display: flex;
    flex-direction: column;
    flex: 2;
}
.bar {
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}
.raised, .goal {
    width: 100%;
    height: 10px;
    border-radius: 4px;
}
.raised {
    z-index: 100;
}
.goal {
    background-color: #818181;
}
.humanitarian_com {
    background-color: #f1f1f1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.shadow_here {
    box-shadow: 2px 0px 5px #88888836
}
.svg_board {
    flex: 1;
    align-self: flex-end;
}
.svg_board_mob .arrow {
    font-family: FontAwesome;
}
.svg_board_mob {
    display: none;
}
.svg_board .arrow  {
    font-family: FontAwesome;
}
.human_details_raised_salary {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1px;
    align-self: flex-end;
    flex: 1;
}
.details_human {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    align-items: space-between;
    flex: 4;
}
.btn_human {
    background: linear-gradient(to bottom, #f1f1f1 0%, #f1f1f1 50%, #fdfdfd 50%, #fdfdfd 100%);
    margin-bottom: 10px;
    padding-bottom: 5px;
}
.circle {
    fill: none;
    stroke-width: 10px;
    stroke: #ee4236;
  }
  
  .circle-bg {
    fill: none;
    stroke-width: 10px;
    stroke: grey;
  }
  .circle2 {
    fill: none;
    stroke-width: 7px;
    stroke: #ee4236;
  }
  
  .circle-bg2 {
    fill: none;
    stroke-width: 7px;
    stroke: grey;
  }
  .circle_svg {
      flex: 1;
  }
  .mob {
      display: none;
  }
  @media (max-width: 1600px) {
    .details_human {
        flex-direction: column;
        align-items: center;
    }
    .svg_board {
        display: none;
    }
    .svg_board_mob {
        display: block;
        margin-top: -80px;
    }
    .mob {
        display: block;
        flex: 1;
    }
    .circle_svg {
        display: none;
    }
    .btn_human {
        margin-bottom: 55px;
    }
}
@media (max-width: 957px) {
    .container_human {
        flex-direction: column;
    }
    .mob {
        display: none;
        flex: 1;
    }
    .circle_svg {
        display: block;
    }
}
@media (max-width: 350px) {
    .details_human {
        margin-top: -80px;
    }
    .mob {
        display: block;
        flex: 1;
    }
    .circle_svg {
        display: none;
    }
}