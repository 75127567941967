#user_sponsor_page {
    min-height: 100vh;
    padding: 40px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 80px;
}
#user_sponsors_display {
    width: 100%;
    display: grid;
    grid-template-columns: 40% 40%;
    justify-content: space-around;
    align-items: center;
    gap: 50px;
}