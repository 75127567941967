.carousel {
    width: 100%;
}
.btn-donate {
    border-radius: 30px;
    padding: 3px 10px 3px 10px;
}
#banner .carousel-indicators {
    background-color: #fdfdfd;
    margin: 0px;
}
#banner .carousel-indicators [data-bs-target] {
    background-color: rgb(99, 99, 99);
    width: 50px;
    height: 3px;
    margin: 20px 0px 0px 0px
}
#banner .carousel-caption {
    padding-bottom: 3rem;
}
#banner .carousel-indicators .active {
    background-color: #ee4236;
}
.donate_btn_icon_banner {
    height: 20px;
    width: 20px;
    margin-bottom: 5px;
}