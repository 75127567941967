#sukuk_sec {
    width: 100%;
    padding: 40px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.sukuk_display {
    padding: 20px;
    width: 100%;
    display: grid;
    grid-template-columns: 40% 40%;
    gap: 50px;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
}
#sukuk_slider {
    padding: 20px;
    display: none;
}
#sukuk_slider .carousel-inner {
    padding: 20px;
}
#sukuk_slider .carousel-control-prev {
    width: 5%;
    justify-content: flex-start;
}
#sukuk_slider .carousel-control-next {
    width: 5%;
    justify-content: flex-end;
}
#sukuk_slider .carousel-control-prev .carousel-control-prev-icon {
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ee4236'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}
#sukuk_slider .carousel-control-next .carousel-control-next-icon {
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ee4236'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}
@media (max-width: 1600px) {
    .sukuk_display {
        grid-template-columns: 100%;
        display: none;
    }
    #sukuk_slider {
        display: unset;
    }
}
@media (max-width: 350px) {
    #sukuk_sec {
        padding: 0px;
    }
}