.popUp .modal-dialog-centered .modal-content {
    background-color: transparent;
    border: none;
    min-height: 700px;
}
.popUp .modal-dialog-centered .modal-content .modal-body {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}
.spin_popUp {
    height: 80px;
    width: 80px;
    border-radius: 50%;
    background: linear-gradient(
        0deg
        , rgb(134, 134, 134) 0%, rgba(214,214,214,1) 100%);
    border: 1px solid black;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.popUp_btns {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
}
.popUp_btns button {
    width: 80%;
    border-radius: 15px;
    font-size: 20px
}
.popUP_outline {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.react-multi-carousel-list {
    width: 100%;
}
