.lang_logo {
    height: 20px;
    width: 20px;
}
.inDrop {
    margin-right: 30px;
}
.dropDown {
    border-radius: 30px;
    background-color: white;
    padding: 3px;
    border: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.dropdown, .dropDown {
    width: auto
}
.fa-shopping-bag, .fa-user, .fa-envelope, .fa-bell {
    color: #ee4236;
    font-size: 25px;
}
#down_header ul {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    gap: 20px;
    flex-wrap: wrap;
    list-style: none;
    margin: 0px;
    flex: 2;
}
.btnForNav{
    text-decoration: none;
    background-color: transparent;
    color: #818181;
    font-size: 17px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1px;
    border: none;
}
#down_header ul li a:hover {
    color: #ec655b;
}
#down_header {
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    flex: 3;
}
#select_lang_area {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
#header {
    background-color: #f5f5f5;
    padding: 20px 35px 20px 35px;
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-end;
    justify-content: space-between;
    
}
.header_btns {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
}
.header_btns button {
    background-color: transparent;
    border: none;
    padding-bottom: 0px;
}
.dropdown-menu {
    min-width: auto;
}
.dropdown-item {
    padding: 10px;
    display: flex;
    align-items: center;
    gap: 5px;
}
.ERC_logo {
    height: 50px;
    width: 200px;
    object-fit: fill;
}
.ERC_logo_mob {
    display: none;
}
.header_icons {
    height: auto;
    width: auto;
    padding: 0px;
}
.bg-danger {
    background-color: #ee4236;
}
@media (max-width: 1000px) {
    #header {
        background-color: #535353e7;
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        z-index: 10000;
    }
    #select_lang_area {
        display: none;
    }
    #down_header ul {
        display: none;
    }
    #down_header {
        align-items: center;
        justify-content: center;
    }
    .ERC_logo {
        display: none;
    }
    .ERC_logo_mob {
        display: block;
    }  
}