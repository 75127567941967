.user_sponsor_com {
    width: 100%;
    padding: 20px;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    border-radius: 20px;
    flex-grow: 1;
}
.user_sponsor_com_above_content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}
.above_content {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 15px;
}
.user_sponsor_img {
    height: 80px;
    width: 80px;
    border-radius: 50%;
    object-fit: cover;
}
.user_sponsor_details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    gap: 10px;
}
.detals_table {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 10px;
    width: 100%;
}
.detals_table label {
    width: 100%;
}
.btn_send_user_spon {
    border-radius: 30px;
    width: 80%;
}
.user_sponsor_com_above_content .dropdown button {
    background-color: white;
    border: 0px;
}
.dropdown-menu {
    border-radius: 10px;
}