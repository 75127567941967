#all_sukuk_com {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 5px;
}
.sukuk_item_board_all {
    width: 100%;
    height: 100%;
    padding: 5px;
    display: flex;
    justify-content: space-between;
    flex: 3;
}
.btn-description {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}