#humanitarian {
    width: 100%;
    padding: 40px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}
#human {
    width: 100%;
    padding: 45px;
}
#human .carousel-indicators {
    background-color: #fdfdfd;
    margin: 0px;
    align-items: center;
}
#human .carousel-indicators [data-bs-target] {
    background-color: rgb(99, 99, 99);
    border-radius: 50%;
    width: 10px;
    height: 10px;
    margin: 20px 5px 0px 0px
}
#human .carousel-caption {
    padding-bottom: 3rem;
}
#human .carousel-indicators .active {
    background-color: #ee4236;
    height: 15px;
    width: 15px;
}
#human .carousel-control-next, #human .carousel-control-prev {
    width: 2%;
    opacity: 1;
}
#human .carousel-control-next-icon  {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ee4236'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e")
}
#human .carousel-control-prev-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ee4236'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}
@media (max-width: 1600px) {
    #human {
        padding: 0px;
    }
    #humanitarian {
        padding: 5px;
    }
}