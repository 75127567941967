.favorites_inc_Dec {
    width: 100%;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    background-color: #df1f0e10;
    border-radius: 18px;
}
.dec, .inc {
    padding: 0.5px 9px;
    border-radius: 42%;
    background-color: #fa8b806e;
    color: #ee4236;
    border: none;
    font-size: 20px;
    font-weight: bold;
}
.favorites_details {
    width: 100%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    border-bottom-left-radius: 35px;
    border-bottom-right-radius: 35px;
    background-color: white;

    
}
.favorites_img {
    border-top-left-radius: 35px;
    border-top-right-radius: 35px;
    width: 200px;
    height: 150px;
    object-fit: cover;
}
.favorites_item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0px;
    
}
.icon_for_favorites {
    height: 48px;
    width: 48px;
    border-radius: 50%;
    background-color: transparent;

}
.favorites_com {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    border-radius: 30px;
    box-shadow: 1px 1px 8px #88888823;

}